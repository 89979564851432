import React, { useState, useRef, useEffect } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image";
import { FaSearch } from 'react-icons/fa';
import { RichText } from 'prismic-reactjs';
import htmlSerializer from '../utils/htmlSerializer';

import LayoutNew from '../components/layout-new';
import Seo from '../components/seo';
import HeroSectionFAQsNew from '../components/sections/hero-section-faqs-new';
import HeroSectionFAQsMobile from '../components/sections/hero-section-faqs-mobile';
import CustomAccordionNew from '../components/common/CustomAccordionNew';

const PageFAQs = () => {
  const data = useStaticQuery(graphql`
    query PageFAQsQuery {
      prismicFaqsPage {
        _previewable
        data {
          page_title
          page_short_description
          page_search_engine_no_index
          hero_section_title
          hero_section_sub_title          
          hero_section_background {
            gatsbyImageData(
              width: 1920
            )
          }
          faqs_section_intro {
            richText
          }          
          search_bar_placeholder_text
          faqs_list {
            question
            answer {
              richText
              text
            }
          }
          meeting_section_title
          meeting_section_sub_title
          button_name
          button_link {
            link_type
            uid
            url
          }
          meeting_section_background {
            gatsbyImageData(
              width: 1920
            )
          }
        }
      }
      imgHero: file(relativePath: { eq: "faqs/backgrounds/hero-bg-full-2.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
          )
        }
      }
    }
  `);

  const doc = data.prismicFaqsPage;
  const imgHero = getImage(doc.data.hero_section_background);
  const imgSrc = getSrc(doc.data.hero_section_background);
  const imgSection = getImage(doc.data.meeting_section_background);
  const buttonLinkType = doc.data.button_link.link_type;
  const buttonLinkUid = doc.data.button_link.uid;
  const buttonLinkUrl = doc.data.button_link.url;
  const faqs = doc.data.faqs_list;

  const [searchTerm, setSearchTerm] = useState('');
  const [matchedIndex, setMatchedIndex] = useState(-1); // Initialize to -1, indicating no match
  const [visibleFaqs, setVisibleFaqs] = useState(6); // State to manage the number of visible FAQs

  const faqRefs = useRef([]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    if (event.key === 'Enter') {
      const matchedIndex = faqs.findIndex(faq =>
        faq.answer.text.toLowerCase().includes(searchTerm.toLowerCase()) ||
        faq.question.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setMatchedIndex(matchedIndex);

      if (matchedIndex !== -1) {
        setVisibleFaqs(matchedIndex + 1); // Ensure all FAQs up to and including the matched FAQ are visible
      }
    }
  };

  const handleClearSearch = () => {
    setSearchTerm('');
    setMatchedIndex(-1);
  };

  useEffect(() => {
    if (matchedIndex !== -1 && faqRefs.current[matchedIndex]) {
      faqRefs.current[matchedIndex].scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [visibleFaqs, matchedIndex]);

  return (
    <LayoutNew>
      <Seo
        title={doc.data.page_title}
        description={doc.data.page_short_description}
        image={imgSrc}
        noIndex={doc.data.page_search_engine_no_index ? true : false}
      />

      <HeroSectionFAQsNew
        title={doc.data.hero_section_title}
        subTitle={doc.data.hero_section_sub_title}
        imgSrc={imgHero}
      />

      <HeroSectionFAQsMobile 
        title={doc.data.hero_section_title}
        subTitle={doc.data.hero_section_sub_title}
        imgSrc={imgHero}
      />

      <div className="bg-site-white pt-8 pb-16 md:pt-16 md:pb-14">
        <div className="faqs-section w-11/12 max-w-screen-lg mx-auto mb-8">
          <RichText
            render={doc.data.faqs_section_intro.richText}
            htmlSerializer={htmlSerializer}
          />
        </div>
        <div className="w-11/12 max-w-screen-sm flex flex-col items-center justify-center mx-auto">
          <div style={{ marginBottom: '20px', position: 'relative', width: '100%' }}>
            <div style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', pointerEvents: 'none' }}>
              <FaSearch size={20} />
            </div>
            <input
              type="text"
              placeholder={doc.data.search_bar_placeholder_text}
              value={searchTerm}
              onChange={handleSearchChange}
              onKeyDown={handleSearchSubmit}
              style={{ padding: '10px 40px 10px 40px', width: '100%', boxSizing: 'border-box', border: '1px solid #3c3c3c', borderRadius: '5px' }}
            />
            {searchTerm && (
              <button onClick={handleClearSearch} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', border: 'none', background: 'none', cursor: 'pointer', padding: '5px' }}>
                <span className='text-site-red' style={{ fontSize: '26px', fontWeight: 'bold' }}>X</span>
              </button>
            )}
          </div>          
        </div>
        
        {faqs.map((faq, index) => (
          <div className="faqs-section-list" key={index} ref={el => faqRefs.current[index] = el}>
            <CustomAccordionNew title={faq.question} isOpen={matchedIndex === index}>
              <RichText
                render={faq.answer.richText}
                htmlSerializer={htmlSerializer}
              />
            </CustomAccordionNew>
          </div>
        ))}
      </div>  

      <div className="relative" style={{ display: "grid", background: "#333333", minHeight: "400px" }}>
        <GatsbyImage
          style={{
            gridArea: "1/1",
            objectFit: "cover",
            opacity: "0.9",
            height: 400,
          }}
          alt="hero background"
          image={imgSection}
          formats={["auto", "webp", "avif"]}
        />

        <div
          style={{
            gridArea: "1/1",
            position: "relative",
            placeItems: "center",
            display: "grid",
          }}
        >
          <div className="w-11/12 max-w-screen-xl flex flex-col items-start justify-center mx-auto py-6 md:py-12">
            <h2 className="text-site-gray-dark text-3xl md:text-4xl text-center md:text-left font-extrabold tracking-wider mb-6">{doc.data.meeting_section_title}</h2>
            <p className='text-site-gray-darker text-xl md:text-2xl text-center md:text-left mb-8 tracking-widest'>{doc.data.meeting_section_sub_title}</p>
            <div className="w-full flex flex-col items-center sm:flex-row sm:justify-start">
              {
                buttonLinkType === "Document"
                ?
                  <Link
                    to={`/${buttonLinkUid}`}
                    className="w-full md:w-6/12"
                  >
                    <button className="w-full bg-site-red hover:bg-site-red-hover text-lg md:text-xl text-white uppercase tracking-wider px-8 py-3">
                      {doc.data.button_name}
                    </button>
                  </Link>
                :
                  buttonLinkType === "Web"
                ?
                  <a
                    href={buttonLinkUrl ? `${buttonLinkUrl}` : "/"}
                    target={buttonLinkUrl ? "_blank" : "_self"}
                    rel={buttonLinkUrl ? "noopener noreferrer" : ""}
                    className="w-full md:w-6/12"
                  >
                    <button className="w-full bg-site-red hover:bg-site-red-hover text-lg md:text-xl text-white uppercase tracking-wider px-8 py-3">
                      {doc.data.button_name}
                    </button>
                  </a>
                :
                  ""
              }
            </div>
          </div>
        </div>
      </div>
    </LayoutNew>
  );
};

export default PageFAQs;