import React, { useState, useEffect } from 'react';

const CustomAccordionNew = ({ title, children, isOpen: initiallyOpen }) => {
  const [isOpen, setIsOpen] = useState(initiallyOpen);

  useEffect(() => {
    setIsOpen(initiallyOpen);
  }, [initiallyOpen]);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={isOpen ? 'w-11/12 max-w-screen-lg pt-16 pb-0 mx-auto' : 'w-11/12 max-w-screen-lg border-b-2 border-site-gray-light pt-12 pb-12 mx-auto'}>
      <div className={isOpen ? `bg-site-red-light mx-auto p-4 rounded-lg` : `mx-auto p-4 rounded-lg`}>
        <div onClick={toggleAccordion} className="w-full flex items-center justify-center" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
          <div className='w-full flex items-start justify-between'>
            <div className='w-10/12'>
              <h3 className={isOpen ? 'text-site-gray-darker text-lg md:text-xl tracking-wider font-extrabold mt-4 mb-8': 'text-site-gray-new hover:text-site-gray-darker text-lg md:text-xl tracking-wider font-extrabold'}>{title}</h3>
            </div>
            {isOpen ? <span className='text-4xl text-site-red ml-4 -mt-2'>x</span> : <span className='text-5xl text-site-gray-new hover:text-site-red ml-4 -mt-2'>+</span>}
          </div>
        </div>
        {isOpen && <div>{children}</div>}
      </div>
    </div>
  );
};

export default CustomAccordionNew;