import * as React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"

const StyledDiv = styled.div`
  background: #dff2f2;
  min-height: 400px;
  height: 400px;

  @media (min-width: 1024px) {
    height: 400px;
  }

  @media (min-width: 1280px) {
    height: 550px;
  }

  @media (min-width: 1536px) {
    height: 600px;
  }
`

const StyledGatsbyImage = styled(GatsbyImage)`
  grid-area: 1/1;
`

const HeroSectionFAQsNew = ({ imgSrc, title, subTitle }) => {
  const data = useStaticQuery(graphql`
    query HeroSectionFAQsNewQuery {
      imgDefault: file(relativePath: { eq: "backgrounds/home-hero-bg.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
          )
        }
      }
    }
  `)

  const imgDefault = getImage(data.imgDefault)

  return (
    <>
      <StyledDiv className="relative hidden md:grid pt-14 lg:pt-20 xl:pt-0 z-20">
        <StyledGatsbyImage
          alt="hero background"
          image={imgSrc ? imgSrc : imgDefault}
          formats={["auto", "webp", "avif"]}
          objectFit="fill"
        />

        <div
          style={{
            gridArea: "1/1",
            position: "relative",
            placeItems: "center",
            display: "grid",
          }}
        >
          {title
            ?
            <div className="w-11/12 max-w-screen-xl flex flex-col items-start justify-start px-2">
              <div className="w-full md:w-8/12 lg:w-6/12 xl:w-5/12 flex flex-col justify-center">
                <div className="md:w-9/12 lg:w-10/12">
                  <h2 className="text-site-gray-dark text-3xl md:text-5xl lg:text-5xl xl:text-6xl font-extrabold text-left tracking-widest xl:mt-16 md:mb-8 xl:mb-16">
                    {title}
                  </h2>
                </div>
                
                <div className="md:w-9/12 lg:w-10/12">
                  <p className="text-site-gray-darker text-1xl md:text-2xl xl:text-3xl tracking-wider">{subTitle}</p>                
                </div>
              </div>
            </div>
            :
            null
          }
        </div>
      </StyledDiv>           
    </>
  )
}

export default HeroSectionFAQsNew
